import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`h-full w-full flex flex-col justify-center items-center flex-grow`}
`

export const Header = styled.h1`
  ${tw`flex flex-col items-center m-0 p-0 text-center mt-8 sm:mt-0`}

  .circle{
    ${tw`
      flex flex-col justify-center items-center w-full sm:w-72
      border-8 border-solid border-lightorange rounded-full
      text-6xl sm:text-8xl text-lightorange font-bold
    `}
    aspect-ratio: 1 / 1;
  }

  .text-wrap{
    ${tw`text-center text-3xl sm:text-5xl text-lightorange uppercase my-8`}
  }
    
  }

`


const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found"/>
    <Wrapper>
      <Header>
        <span className="circle">404</span> 
        <span className="text-wrap">Not Found</span>
      </Header>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
